import classNames from "classnames";
import { isFunction, isNil, uniqueId } from "lodash";
import { Input, InputProps } from "PFComponents/text/input";
import { TextArea, TextAreaProps } from "PFComponents/text/text_area";
import { getAriaProps } from "PFCore/helpers/get_aria_props";
import { IconName } from "PFTheme/graphics/icons";
import {
  AriaAttributes,
  CSSProperties,
  ForwardedRef,
  forwardRef,
  HTMLInputTypeAttribute,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useToggle } from "react-use";

import { Icon } from "../../icon";
import { Typography } from "../../typography";
import { InputFieldIcons, InputFieldIconsList } from "./components/input_field_icons";
import { Label } from "./components/label";
import { LockedIcon } from "./components/locked_icon";
import { PasswordToggle } from "./components/password_toggle";
import { Tip } from "./components/tip";
import css from "./input_field_set.module.scss";

const INPUT_RIGHT_PADDING = 8;

export type InputFieldSetProps = (
  | ({ inputType: "textarea" } & Omit<TextAreaProps, "ref">)
  | ({ inputType?: HTMLInputTypeAttribute } & Omit<InputProps, "type" | "ref">)
) & {
  label?: ReactElement<{ name?: string; title?: string }> | string;
  labelTooltip?: {
    content: string;
    icon: IconName;
  };
  labelHidden?: boolean;
  qaId?: string;

  active?: boolean;
  required?: boolean;

  inline?: boolean;
  locked?: boolean;
  lockedTip?: string;
  lockedTipPosition?: "top" | "bottom";

  tip?: string;
  error?: ReactNode | boolean | null;
  warning?: ReactNode | boolean | null;

  maxLength?: number;
  minVisibleMaxLengthFactor?: number;
  showPasswordToggle?: boolean;

  className?: string;
  inputClassName?: string;

  style?: CSSProperties;
  /* Property for internal usage to style the custom Select values. Not intended for external usage */
  inputStyle?: CSSProperties;

  icon?: IconName;
  actions?: InputFieldIconsList;
  dataInputName?: string;
} & AriaAttributes;
export const InputFieldSet = forwardRef(
  (
    {
      qaId,
      id,
      title,
      placeholder,
      active,
      disabled,
      required,
      className,
      inputClassName,
      style,
      inputStyle,
      inputType,
      label,
      labelTooltip,
      labelHidden,
      inline,
      locked,
      lockedTip,
      lockedTipPosition = "top",
      maxLength,
      minVisibleMaxLengthFactor = -1,
      name,
      onBlur,
      onFocus,
      onKeyDown,
      readOnly,
      showPasswordToggle,
      value,
      tip,
      error,
      warning,
      dataInputName,
      icon,
      actions,
      ...rest
    }: InputFieldSetProps,
    ref: ForwardedRef<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { current: tagId } = useRef(id || uniqueId(name));
    const [showPassword, handlePasswordToggle] = useToggle(false);
    const [isFocused, setIsFocused] = useState(false);
    const valueLength = useMemo(() => (value ? String(value).length : 0), [value]);
    const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);
    const internalIconsRef = useRef<HTMLLabelElement>(null);

    const { ariaProps, rest: inputProps } = getAriaProps(rest);

    const handleFocus = useCallback(
      (...args) => {
        setIsFocused(true);
        //@ts-ignore-next-line
        onFocus?.(...args);
      },
      [onFocus]
    );

    const handleBlur = useCallback(
      (...args) => {
        setIsFocused(false);
        //@ts-ignore-next-line
        onBlur?.(...args);
      },
      [onBlur]
    );

    const calcInputType = inputType === "password" && showPassword ? "text" : inputType;

    const Tag = inputType === "textarea" ? TextArea : Input;
    const showMaxLength = maxLength && valueLength > minVisibleMaxLengthFactor * maxLength;

    const shouldRenderFooter = error || warning || tip || showMaxLength;

    const rootCss = classNames(
      css.root,
      {
        [css.readOnly]: readOnly,
        [css.focused]: isFocused,
        [css.inline]: inline,

        [css.active]: inputRef.current?.value || active || isFocused || !!valueLength || !!placeholder,
        [css.locked]: locked
      },
      className
    );

    const inputContainerCss = classNames(css.wrap);

    const inputCss = classNames(css.input, inputClassName, {
      [css.disabled]: disabled || locked,
      [css.error]: error,
      [css.warning]: warning
    });

    useEffect(() => {
      if (isFunction(ref)) {
        ref(inputRef.current);
      } else if (ref) {
        ref.current = inputRef.current;
      }
    }, []);

    const labelText =
      (label as ReactElement)?.props?.title || (label as ReactElement)?.props?.name || (label as string);

    const finalInputStyle = {
      ...inputStyle,
      paddingRight: (internalIconsRef.current?.offsetWidth || 0) + INPUT_RIGHT_PADDING
    };

    return (
      <div className={rootCss} data-qa-id={qaId} style={style}>
        {label && (
          <div className={css.labelRow}>
            <Label
              label={labelText}
              labelTooltip={labelTooltip}
              hidden={labelHidden}
              required={required}
              tagId={tagId}
            />
            <InputFieldIcons icons={actions || []} disabled={locked} />
          </div>
        )}
        <div className={inputContainerCss}>
          <Tag
            {...inputProps}
            style={finalInputStyle}
            aria-required={required}
            className={inputCss}
            // @ts-ignore ts cannot predict if Tag is TextArea or Input
            ref={inputRef}
            id={tagId}
            value={!isNil(value) ? String(value) : value}
            readOnly={readOnly}
            placeholder={placeholder}
            disabled={locked || disabled}
            title={labelText || ariaProps["aria-label"]}
            aria-disabled={locked || disabled}
            type={calcInputType}
            onFocus={handleFocus}
            onBlur={handleBlur}
            //@ts-ignore-next-line
            onKeyDown={onKeyDown}
            maxLength={maxLength}
            data-input-name={dataInputName}
            aria-activedescendant={ariaProps["aria-activedescendant"]}
          />
          <label htmlFor={tagId} ref={internalIconsRef} className={css.internalIcons}>
            {showPasswordToggle && inputType === "password" && (
              <PasswordToggle showPassword={showPassword} onClick={handlePasswordToggle} />
            )}
            {!label && <InputFieldIcons icons={actions || []} disabled={locked} />}
            {!locked && icon && <Icon size="sm" name={icon} className={css.icon} />}
            {locked && <LockedIcon lockedTip={lockedTip} lockedTipPosition={lockedTipPosition} />}
          </label>
        </div>
        {shouldRenderFooter && (
          <div className={css.footer}>
            {(error || warning || tip) && <Tip error={error} warning={warning} tip={tip} />}
            {showMaxLength && (
              <Typography
                variant="labelRegular"
                className={css.maxLength}
              >{`${valueLength} / ${maxLength}`}</Typography>
            )}
          </div>
        )}
      </div>
    );
  }
);

InputFieldSet.displayName = "InputFieldSet";
