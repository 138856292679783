import { Button } from "PFComponents/button";
import ProgressBar from "PFComponents/progress_bar/progress_bar";
import { getProfileName, ProfileName } from "PFCore/helpers/profile";
import useIsFeatureEnabled from "PFCore/helpers/use_is_feature_enabled";
import colors from "PFTheme/tokens/colors";
import { FeatureFlag } from "PFTypes";
import { useTranslation } from "react-i18next";

import css from "../../../../parts/overview/detail_view/detail_view.module.scss";
import { useProfileDetailContext } from "../../profile_detail_context_provider";
import { Label } from "../parts/label";

interface DetailsProps {
  profile: ProfileName;
  bookingsTotal: number;
  utilization: number | undefined;
}

export const Details = ({ profile, bookingsTotal, utilization }: DetailsProps): JSX.Element => {
  const { t } = useTranslation("bookingModule");
  const { onBookingClick } = useProfileDetailContext();
  const fullName = getProfileName(profile);
  const showUtilizationMetrics = useIsFeatureEnabled()(FeatureFlag.ShowUtilization);

  return (
    <span className={css.details}>
      <span className={css.item}>
        <Label icon="list" text={t("details.bookings")} />
        <Button kind="blank" onClick={() => onBookingClick?.(fullName)}>
          {bookingsTotal}
        </Button>
      </span>
      {showUtilizationMetrics && (
        <span className={css.item}>
          <Label text={t("details.profile.utilization", { utilization })} />
          <ProgressBar
            className={css.progressBar}
            values={[{ value: utilization ?? 0, color: colors.paletteEcharts1 }]}
            totalValue={100}
          />
        </span>
      )}
    </span>
  );
};
