import classNames from "classnames";
import moment from "moment";
import { DetailsPanelContextProvider } from "PFApp/booking/components/details_panel/details_panel_context/details_panel_context_provider";
import useBookingModulePermissions from "PFApp/use_booking_module_permissions";
import useIsAvailabilityPermitted from "PFApp/use_is_availability_permitted";
import BookingsCalendar from "PFComponents/calendar/bookings_calendar/bookings_calendar";
import { useCurrentAccount } from "PFCore/hooks/queries/account";
import { useBookingApprovalsInvalidate } from "PFCore/hooks/queries/bookings/approvals/use_booking_approvals_invalidate"; // eslint-disable-line
import { useCurrentProfile } from "PFCore/hooks/queries/profile";
import { useProfileAvailabilitiesPerDay } from "PFCore/hooks/queries/profile/use_profile_availabilities_per_day";
import useAvailabilityLimits from "PFCore/hooks/use_availability_limits";
import useBookingCategories from "PFCore/hooks/use_booking_categories";
import { fetchProfileAsAdmin } from "PFCore/services/profile";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useTranslation } from "react-i18next";

import { BookingFormContextProvider, useBookingFormContext } from "../../booking/components/booking_form";
import { BookingFormMode } from "../../booking/components/booking_form/use_booking_form";
import { DeleteBookingModalContextProvider } from "../../booking/components/delete_booking_modal/delete_booking_modal_context_provider";
import { DetailsPanel, useDetailsPanelApiContext } from "../../booking/components/details_panel";
import { ReassignActionContextProvider } from "../../booking/parts/overview/booking_module_calendar/context/reassign_action_context_provider";
import css from "./availability.less";
import { getProfilesAvailabilityDateRange } from "./availability.utils";
import { BookingsList } from "./bookings_list";
import { ProfileBookingsProvider, useProfileBookingsContext } from "./profile_bookings_provider";

export const AvailabilityContent = ({
  profile,
  isLoading,
  activeViews = [],
  isAdmin,
  showTip,
  readOnly = false,
  isExpanded = false
}) => {
  const { t } = useTranslation("availability");
  const {
    selectedBooking,
    selectBooking,
    bookings,
    refreshBookings,
    handleDateRangeChange,
    jobCodeDisplayAs,
    calendarSearch
  } = useProfileBookingsContext();
  const { openDetailsPanel, closeDetailsPanel } = useDetailsPanelApiContext();

  const { isEnabled: isBookingModuleEnabled } = useBookingModulePermissions();
  const canEditAvailability = useIsAvailabilityPermitted(profile, ["rw"]) && !readOnly;

  const { invalidate: invalidateBookingApprovals } = useBookingApprovalsInvalidate();
  const { minDate, maxDate } = useAvailabilityLimits();

  const canEdit = !readOnly && canEditAvailability;

  const { modal } = useBookingFormContext();

  const { getCategory, bookingCategories } = useBookingCategories();

  const [displayMonth, setDisplayMonth] = useState(moment());

  const { minDisplayedDate, maxDisplayedDate } = getProfilesAvailabilityDateRange(displayMonth);

  const { data: profileAvailabilities } = useProfileAvailabilitiesPerDay({
    profileId: profile.id,
    dateRange: {
      min: minDisplayedDate,
      max: maxDisplayedDate
    }
  });

  const handlePeriodClick = (period) => {
    selectBooking(period);
    setDisplayMonth(moment.utc(period.start_date));
  };

  const isListView = activeViews.includes("list");
  const isCalendarView = activeViews.includes("calendar");

  const handleEdit = ({ id }) =>
    modal.open(
      {
        bookingId: id,
        profileId: profile.id
      },
      { mode: BookingFormMode.Edit, onSuccess: handleBookingsUpdate }
    );

  useEffect(() => {
    if (!selectedBooking) {
      closeDetailsPanel();
      return;
    }

    openDetailsPanel({
      type: "booking",
      id: selectedBooking.id,
      category: getCategory(selectedBooking),
      isEditPermitted: canEdit && selectedBooking.source === "ui"
    });
  }, [selectedBooking]);

  useEffect(() => {
    if (isListView) {
      handleDateRangeChange?.(
        moment.utc(displayMonth).startOf("month"),
        moment(displayMonth).endOf("month"),
        displayMonth
      );
    }
  }, [isListView, displayMonth]);

  const handleBookingsUpdate = () => {
    invalidateBookingApprovals();
    refreshBookings();
  };

  const handleBookingDeletion = async () => {
    selectBooking(null);
    await handleBookingsUpdate();
  };

  const tip =
    showTip &&
    (!canEdit ? t("tip.content.readOnly") : isAdmin ? t("tip.content.admin") : t("tip.content.user"));

  return (
    <div className={classNames(css.root, { [css.listView]: isListView })}>
      {/* TODO: [PROF-6410] Refactor AvailabilityContext to get rid of createPortal */}
      {isBookingModuleEnabled &&
        createPortal(
          <DetailsPanel onDeleted={handleBookingDeletion} onEdited={handleBookingsUpdate} />,
          document.getElementById("react_app")
        )}
      {tip && (
        <>
          <div style={{ opacity: 0.7 }}>{t("tip.head")}</div>
          <small className={css.tip}>{tip}</small>
        </>
      )}
      <>
        {isCalendarView && (
          <BookingsCalendar
            darkBg
            calendars={profile.availability.calendars}
            bookings={bookings}
            bookingCategories={bookingCategories}
            style={{ maxWidth: "100%" }}
            minDate={minDate}
            maxDate={maxDate}
            onClick={selectBooking}
            displayMonth={displayMonth}
            setDisplayMonth={setDisplayMonth}
            jobCodeDisplayAs={jobCodeDisplayAs}
            onDisplayRangeChange={handleDateRangeChange}
            isExpanded={isExpanded}
            availabilities={profileAvailabilities?.entries ?? []}
            calendarSearch={calendarSearch}
          />
        )}
        {isListView && (
          <BookingsList
            canEdit={canEdit}
            isLoading={isLoading}
            onBookingClick={handlePeriodClick}
            onBookingEdit={handleEdit}
            minDate={minDate}
            maxDate={maxDate}
            displayMonth={displayMonth}
            setDisplayMonth={setDisplayMonth}
            hideMonthSelector={isCalendarView}
            isExpanded={isExpanded}
          />
        )}
      </>
    </div>
  );
};

export const Availability = ({ isAdmin, profile, ...props }) => (
  <AvailabilityContext profile={profile} isAdmin={isAdmin}>
    <AvailabilityContent isAdmin={isAdmin} profile={profile} {...props} />
  </AvailabilityContext>
);

export const AvailabilityContext = ({ isAdmin, profile, children, isSearchEnabled }) => {
  const { data: currentAccount } = useCurrentAccount();

  const { data: currentProfile } = useCurrentProfile();

  return (
    <ProfileBookingsProvider
      currentProfile={currentProfile}
      profile={profile}
      bookingCategories={currentAccount.availability?.booking_categories}
      isSearchEnabled={isSearchEnabled}
    >
      <DetailsPanelContextProvider>
        <ReassignActionContextProvider>
          <BookingFormContextProvider fetchProfile={isAdmin && fetchProfileAsAdmin} profileId={profile.id}>
            <DeleteBookingModalContextProvider>{children}</DeleteBookingModalContextProvider>
          </BookingFormContextProvider>
        </ReassignActionContextProvider>
      </DetailsPanelContextProvider>
    </ProfileBookingsProvider>
  );
};

const contextProps = {
  profile: PropTypes.shape({ id: PropTypes.number, availability: PropTypes.object }),
  isAdmin: PropTypes.bool,
  children: PropTypes.node,
  isSearchEnabled: PropTypes.bool
};

const props = {
  isLoading: PropTypes.bool,
  activeViews: PropTypes.arrayOf(PropTypes.string),
  showTip: PropTypes.bool,
  readOnly: PropTypes.bool,
  profile: PropTypes.shape({ id: PropTypes.number, availability: PropTypes.object }),
  isAdmin: PropTypes.bool,
  isExpanded: PropTypes.bool
};

Availability.propTypes = props;

AvailabilityContent.propTypes = props;

AvailabilityContext.propTypes = contextProps;

AvailabilityContent.defaultProps = {
  activeViews: ["calendar", "list"],
  readOnly: false
};

export default Availability;
