import { ColumnHelper } from "@tanstack/react-table";
import { Checkbox } from "PFComponents/checkbox";

import { Radios } from "../../radios/radios";
import { TableData } from "../table";
import { BulkSelect } from "./table_bulk_select";
import css from "./table_bulk_select.module.scss";

export const generateSelectColumn = <TABLE_DATA extends TableData>(
  columnHelper: ColumnHelper<TABLE_DATA>,
  enableMultiRowSelection: boolean
) =>
  columnHelper.accessor((row) => row, {
    id: "select",
    enableSorting: false,
    header: (info) =>
      enableMultiRowSelection ? (
        <BulkSelect
          onChange={info?.table?.getToggleAllRowsSelectedHandler()}
          isAllSelected={info?.table?.getIsAllRowsSelected()}
          isPartiallySelected={!!info?.table?.getIsSomeRowsSelected()}
        />
      ) : null,
    cell: ({ row }) => {
      if (enableMultiRowSelection) {
        return <Checkbox checked={row.getIsSelected()} onChange={(checked) => row.toggleSelected(checked)} />;
      }
      return (
        <Radios
          name="rowSelector"
          items={[
            {
              id: row.id
            }
          ]}
          onChange={() => row.toggleSelected(true)}
          value={row.getIsSelected() ? row.id : undefined}
          classes={{
            root: css.radioRoot
          }}
        />
      );
    },
    size: 22
  });
