import classNames from "classnames";
import { isString } from "lodash";
import { getAriaProps } from "PFCore/helpers/get_aria_props";
import { IconName } from "PFTheme/graphics/icons";
import { AriaAttributes, forwardRef } from "react";
import { humanize } from "underscore.string";

import { Icon } from "../icon";
import css from "./button.module.scss";

// Usage of autosuggest should be replaced with clickable badge
export type ButtonKind =
  | "primary"
  | "secondary"
  | "tertiary"
  | "ghost"
  | "danger"
  | "text"
  | "blank"
  | "autosuggest";

type ConditionalProps =
  | {
      icon?: never;
      iconPlacement?: never;
      text?: never;
      children: React.ReactNode;
    }
  | {
      icon?: IconName;
      iconPlacement?: "left" | "right";
      text?: string;
      children?: never;
    };

export type ButtonProps = ConditionalProps &
  AriaAttributes & {
    href?: string;
    target?: "_blank" | "_self";
    disabled?: boolean;
    kind?: ButtonKind;
    small?: boolean;
    style?: React.CSSProperties;
    qaId?: string | number;
    className?: string;
    id?: string;
    download?: string;
    title?: string;
    tag?: "a" | "button";
    type?: "button" | "submit" | "reset";
    form?: string;
    onClick?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    onKeyDown?: (event: React.KeyboardEvent<HTMLElement>) => void;
    onMouseEnter?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    onMouseLeave?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
    onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
  };

export const Button = forwardRef(
  (
    {
      children,
      icon,
      text,
      kind = "primary",
      tag: Tag = "button",
      disabled,
      small,
      style,
      className,
      id,
      qaId,
      title,
      // button props
      type,
      form,
      // anchor props
      href,
      target,
      download,
      iconPlacement = "left",
      onClick,
      onKeyDown,
      onMouseEnter,
      onMouseLeave,
      onBlur,
      ...props
    }: ButtonProps,
    ref
  ) => {
    const { ariaProps } = getAriaProps(props);

    const iconOnly = icon && !text && !children;
    const tagClassName = classNames(css.button, className, css[`button-${kind}`], {
      [css.buttonSmall]: small,
      [css.iconOnly]: iconOnly && !small,
      [css.iconOnlySmall]: iconOnly && !!small
    });

    const renderButtonContent = () => {
      if (children) {
        return children;
      }

      const iconComponent = icon && <Icon name={icon} size="sm" />;

      return (
        <>
          {iconPlacement === "left" && iconComponent}
          {text && <span className={css.text}>{text}</span>}
          {iconPlacement === "right" && iconComponent}
        </>
      );
    };

    const finalTitle = title || text || (isString(children) && children) || humanize(icon || "");

    return (
      <Tag
        aria-label={props["aria-label"] || finalTitle}
        aria-disabled={disabled}
        ref={ref as any}
        disabled={disabled}
        href={href}
        target={target}
        rel={target === "_blank" ? "noopener noreferrer" : undefined}
        className={tagClassName}
        id={id}
        style={style}
        data-qa-id={qaId}
        download={download}
        title={finalTitle}
        type={type}
        form={form}
        onClick={onClick}
        onKeyDown={onKeyDown}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onBlur={onBlur}
        {...ariaProps}
      >
        {renderButtonContent()}
      </Tag>
    );
  }
);

Button.displayName = "Button";
