import classNames from "classnames";
import { ChangeEvent } from "cleave.js/react/props";
import { uniqueId } from "lodash";
import { EventKey } from "PFTypes/event_key";
import { KeyboardEventHandler, MouseEventHandler, useCallback } from "react";

import { Typography } from "../typography";
import css from "./toggle.module.scss";
import { ToggleReadOnlyIcon } from "./toggle_read_only_icon";

export type ToggleProps = {
  label?: string | JSX.Element;
  tip?: string | JSX.Element;
  inline?: boolean;
  compact?: boolean;
  qaId?: string;
  checked?: boolean;
  disabled?: boolean;
  description?: string | JSX.Element;
  onChange: (value: boolean) => void;
  reversed?: boolean;
  toggleReversed?: boolean;
  error?: string;
  readOnly?: boolean;
  className?: string;
  required?: boolean;
};

const Toggle = ({
  label = "",
  tip = "",
  inline,
  qaId = "Toggle",
  checked = false,
  disabled,
  description,
  onChange,
  reversed,
  toggleReversed,
  error,
  readOnly,
  compact,
  className,
  required
}: ToggleProps): JSX.Element => {
  const toggleId = uniqueId("toggle");

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (readOnly) {
        return;
      }

      event.stopPropagation();
      onChange(event.target.checked);
    },
    [readOnly, onChange]
  );

  const toggle = useCallback(() => {
    onChange(!checked);
  }, [checked, onChange]);

  const handleClick: MouseEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (readOnly) {
        return;
      }

      event.stopPropagation();
      toggle();
    },
    [readOnly, toggle]
  );

  const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = useCallback(
    (event) => {
      if (event.key === EventKey.Enter) {
        toggle();
      }
    },
    [toggle]
  );

  return (
    <div className={classNames(css.root, className)}>
      <div
        className={classNames(css.toggleContainer, {
          [css.readOnly]: readOnly,
          [css.errorLabel]: error,
          [css.labelInline]: inline,
          [css.labelVertical]: !inline,
          [css.disabled]: disabled,
          [css.reversed]: reversed
        })}
      >
        {label && (
          <Typography
            id="toggle-label"
            tag="label"
            variant="labelRegular"
            htmlFor={toggleId}
            className={css.label}
          >
            {label}
            {required && "*"}
          </Typography>
        )}
        <div
          role="switch"
          aria-labelledby="toggle-label"
          aria-checked={checked}
          className={classNames(css.inputContainer, { [css.compact]: compact })}
          style={{
            flexDirection: toggleReversed ? "row-reverse" : "row"
          }}
          data-qa-id={qaId}
          onClick={handleClick}
          onKeyDown={handleKeyDown}
          tabIndex={0}
        >
          {readOnly ? (
            <ToggleReadOnlyIcon checked={checked} />
          ) : (
            <>
              <input
                id={toggleId}
                className={css.input}
                type="checkbox"
                checked={checked}
                disabled={disabled}
                onChange={handleChange}
              />
              <div className={css.switch} />
            </>
          )}
          {description && (
            <Typography variant="bodyRegular" tag="span">
              {description}
              {required && "*"}
            </Typography>
          )}
        </div>
      </div>

      {!error && tip && (
        <div className={css.tip}>
          <Typography variant="labelRegular">{tip}</Typography>
        </div>
      )}
      {error && (
        <div className={css.error}>
          <Typography variant="labelRegular">{error}</Typography>
        </div>
      )}
    </div>
  );
};

export default Toggle;
