import { IconName } from "PFTheme/graphics/icons";
import { Id } from "PFTypes";
import { ReactNode } from "react";

type FlatOptions<T> = {
  options: Option<T>[];
  onCreateOption?: (option: Option<T>) => void;
};

type GroupOptions<T> = {
  onCreateOption?: never;
  options: GroupOption<T>[];
};

type StaticOptions<T> = {
  fetchOptions?: never;
  cacheTime?: never;
} & (FlatOptions<T> | GroupOptions<T>);

type FetchOptions<T> = {
  options?: never;
  fetchOptions: (payload: { ids?: Id[]; searchTerm?: string }) => Promise<Option<T>[]>;
  onCreateOption?: (option: Option<T>) => Promise<Option<T>>;
  cacheTime?: number;
};

type SingleValue<T> = {
  multiple?: false;
  value: Id | undefined;
  renderDisplayValue?: (value: Option<T> | undefined) => ReactNode;
};

type MultipleValue<T> = {
  multiple: true;
  value: Id[];
  renderDisplayValue?: (value: Option<T>[]) => ReactNode;
};

type SelectCommonProps<T> = {
  label?: string;
  labelTooltip?: {
    content: string;
    icon: IconName;
  };
  required?: boolean;
  onRestore?: VoidFunction;
  onClear?: VoidFunction;
  searchableVariant?: SearchableVariant;
  disabled?: boolean;
  locked?: boolean;
  displayValueBelow?: boolean;
  onChange: (options: Option<T> | Option<T>[]) => void;
  onDropdownClose?: VoidFunction;
};

export enum SearchableVariant {
  Always = "always",
  Never = "never",
  Dynamic = "dynamic"
}

export type OptionOriginal = unknown;

export type ResultOption<T> = Option<T> & {
  onChange?: (option: Option<T>) => void;
  selected?: boolean;
};

export type Option<T> = {
  id: Id;
  value: string;
  displayElement?: ReactNode;
  disabled?: boolean;
  original?: T;
};

export type GroupOption<T> = {
  id: Id;
  label?: string;
  options: Option<T>[];
  disabled?: boolean;
};

export type SelectOptions<T> = Option<T>[] | GroupOption<T>[];

export type OptionsProps<T> = StaticOptions<T> | FetchOptions<T>;

export type ValueProps<T> = SingleValue<T> | MultipleValue<T>;

export type SelectV2Props<T = OptionOriginal> = SelectCommonProps<T> & OptionsProps<T> & ValueProps<T>;
