import classNames from "classnames";

import css from "./tile.module.scss";

type TileProps = {
  className?: string;
  light?: boolean;
  paddingVariant?: "none" | "sm" | "lg" | "xl";
  children?: React.ReactNode;
};

export const Tile = ({ className, children, light, paddingVariant = "sm", ...props }: TileProps) => (
  <div
    className={classNames(css.tile, className, {
      [css.light]: light,
      [css.noPadding]: paddingVariant === "none",
      [css.smallPadding]: paddingVariant === "sm",
      [css.largePadding]: paddingVariant === "lg",
      [css.xlargePadding]: paddingVariant === "xl"
    })}
    {...props}
  >
    {children}
  </div>
);
