import { Checkbox } from "PFComponents/checkbox";
import { Typography } from "PFComponents/typography";
import React from "react";
import { useTranslation } from "react-i18next";

import css from "./suggested_changes.module.scss";

type DeleteBookingActionProps = {
  shouldBeRemoved: boolean;
  onToggle: () => void;
};

export const DeleteBookingAction = ({ shouldBeRemoved, onToggle }: DeleteBookingActionProps) => {
  const { t } = useTranslation("bookingModule", { keyPrefix: "bookings.createWithOverbooking.modal" });

  return (
    <div className={css.deleteBookingMessage}>
      <Typography variant="bodyRegular" tag="span">
        {t("deleteInfo")}
      </Typography>
      <Checkbox label={t("deleteAction")} onChange={onToggle} checked={shouldBeRemoved} />
    </div>
  );
};
